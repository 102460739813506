import React from "react";
import { Box, Container, Grid, Typography, Divider } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = (): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "black",
        color: "white",
        top: 0,
      }}
    >
      <Container className="footer" maxWidth="lg">
        <Grid container spacing={4} sx={{ padding: "20px 0" }}>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h1"
              sx={{ fontFamily: "Poppins-Thin, Helvetica", fontWeight: "300" }}
            >
              Glam
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontFamily: "Poppins-ExtraBold, Helvetica",
                  fontWeight: "800",
                }}
              >
                9
              </Typography>
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2, color:"white" }}>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins-SemiBold, Helvetica",
                fontWeight: "600",
              }}
            >
              About Glam9
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1,color:"white" }}>
              Careers
              <br />
              Customer Support
              <br />
              Blog
              <br />
              Sitemap
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins-SemiBold, Helvetica",
                fontWeight: "600",
              }}
            >
              Glam9 Business
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1,color:"white" }}>
              For Partner
              <br />
              Pricing
              <br />
              Support
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins-SemiBold, Helvetica",
                fontWeight: "600",
              }}
            >
              Legal
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, color:"white" }}>
              Privacy Policy
              <br />
              Terms of Services
              <br />
              Terms of Use
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins-SemiBold, Helvetica",
                fontWeight: "600",
              }}
            >
              Our Social Media Profiles
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: 2,
              }}
            >
              <FacebookIcon fontSize="large" />
              <InstagramIcon fontSize="large" />
              <TwitterIcon fontSize="large" />
              <LinkedInIcon fontSize="large" />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: "white", marginY: 2 }} />
        <Typography
          variant="body2"
          align="center"
          sx={{
            fontFamily: "Poppins-Light, Helvetica",
            fontWeight: "300",
            paddingBottom: "20px",
          }}
        >
          Copyright © 2024 glam9
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
