import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ImageListItem,
  ImageListItemBar,
  Box,
  CardMedia,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Home.css";
import { useAppTranslation } from "../../Utils/i18n";
//import CustomButton from "../customButton/CustomButton";
import { CommonAPI } from "../../../src/hooks/common";
import { DashboardAPI } from "../../../src/hooks/dashboard";
import toast from "react-hot-toast";
import InputAdornment from "@mui/material/InputAdornment";
import { ToggleButton } from "@mui/material";

// Interface
import { CityListResponse, CityList } from "../../../src/api/types/common";

const Home: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [service, setService] = useState("");
  const [searchError, setSearchError] = useState("");
  const [location, setLocation] = useState("");
  const [sort, setSort] = useState("");
  const [dashboardData, setDashboardData] = useState<any>({});
  const [cityData, setCityData] = useState<CityList[]>([]);
  const [businessType, setBusinessType] = useState("");
  const [salonTypeData, setSalonTypeData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const { getCityListAPICall, getDropdownListOptionsAPICall } = CommonAPI();
  const { getDashboardDataAPICall } = DashboardAPI();

  const gotoShop = (vendor_detail_id: number) => {};

  const searchData = () => {
    if (!service && !location && !sort && !businessType) {
      setSearchError("Please enter any of the above parameters");
      return;
    } else {
      // Perform your search functionality here
      console.log("Searching with parameters:", {
        service,
        location,
        sort,
        businessType,
      });
      getDashboardDataFunc(service, location, sort, businessType);
      setIsSearch(true);
    }
  };

  function clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  const clearSearch = () => {
    setService("");
    setLocation("");
    setSort("");
    setBusinessType("");
    setSearchError("");
    setIsSearch(false);
    // Optionally, refresh dashboard data if needed
    getDashboardDataFunc("", "", "", "");
  };

  const getCityListFunc = async () => {
    const stateId = 12;
    const coutnryId = 101;
    const result: CityListResponse | null = await getCityListAPICall(
      stateId,
      coutnryId,
    );
    if (result === null) {
      toast.error("Failed to fetch city data.");
      return; // Exit the function early if the result is null
    }
    if (result?.status_code == 200) {
      setCityData(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  const getDropdownListOptionsFunc = async () => {
    const result: any | null = await getDropdownListOptionsAPICall();
    if (result === null) {
      toast.error("Failed to fetch dashboard data.");
      return; // Exit the function early if the result is null
    }
    if (result?.status_code == 200) {
      setSalonTypeData(result.data.salon_types);
    } else {
      toast.error(result?.message);
    }
  };

  const getDashboardDataFunc = async (
    search_keyword: string,
    location: string,
    sort_by: string,
    salon_type: string,
  ) => {
    const result: any | null = await getDashboardDataAPICall(
      search_keyword,
      location,
      sort_by,
      salon_type,
    );
    if (result === null) {
      toast.error("Failed to fetch dropdown list data.");
      return; // Exit the function early if the result is null
    }
    if (result?.status_code == 200) {
      console.log(result.data, "result.data");

      setDashboardData(result.data);
    } else {
      toast.error(result?.message);
    }
  };

  // On Load Get Data
  useEffect(() => {
    clearStorage();
    getCityListFunc();
    getDropdownListOptionsFunc();
    getDashboardDataFunc(service, location, sort, businessType);
  }, []);

  console.log(cityData, "setCityData");
  console.log(salonTypeData, "salonTypeData");
  console.log(dashboardData, "dashboardData");
  console.log(dashboardData, "dashboardData");

  return (
    <Box
      sx={{
        width: "100%",
        padding: "40px",
      }}
    >
      <Box sx={{ padding: "40px", textAlign: "center" }}>
        <Typography variant="h2" className="searchTitle">
          To book local
          <br />
          <span> beauty</span> and <span>wellness services</span>
        </Typography>
      </Box>

      <Grid container spacing={3} className="Home">
        <Grid item xs={12}>
          <Grid container spacing={2} className="searchBy">
            <Grid item xs={3}>
              <TextField
                label={t("Salon, Name, Category ...")}
                variant="outlined"
                value={service}
                onChange={(e) => setService(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={icons.service} alt="Service Icon" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="location-label">{t("City")}</InputLabel>
                <Select
                  labelId="location-label"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  label={t("City")}
                >
                  <MenuItem value="">
                    <em>{t("City")}</em>
                  </MenuItem>
                  {cityData.map((row: any) => (
                    <MenuItem key={row?.id} value={row?.id}>
                      {row?.city_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="sort-label">{t("Sort By")}</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  label={t("Sort By")}
                >
                  <MenuItem value="">
                    <em>{t("Sort By")}</em>
                  </MenuItem>
                  <MenuItem value="1">{t("Price High to Low")}</MenuItem>
                  <MenuItem value="2">{t("Price Low to High")}</MenuItem>
                  <MenuItem value="3">{t("Rating High to Low")}</MenuItem>
                  <MenuItem value="4">{t("Rating Low to High")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="business-type-label">
                  {t("Salon Type")}
                </InputLabel>
                <Select
                  labelId="business-type-label"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  label={t("Salon Type")}
                >
                  <MenuItem value="">
                    <em>{t("Salon Type")}</em>
                  </MenuItem>
                  {salonTypeData.map((row: any) => (
                    <MenuItem key={row?.id} value={row?.id}>
                      {row?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="caption" color="error" align="center">
            {searchError}
          </Typography>

          <Box sx={{ textAlign: "center", marginTop: 10 }}>
            <Button
              className={"searchButton"}
              onClick={() => {
                searchData();
              }}
            >
              <h2>Search</h2>
            </Button>
            {isSearch && (service || location || sort || businessType) ? (
              <Button
                className={"clearSearchButton"}
                onClick={() => {
                  clearSearch();
                }}
              >
                <h2>Clear Search</h2>
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Grid>

        <Grid container spacing={3} marginTop={10} className="Home">
          <Grid item xs={6}>
            <Box
              sx={{
                height: "500px",
                width: "100%",
                position: "relative", // Relative positioning to allow absolute children
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "flex-start", // Align contents to the left
                backgroundColor: "black", // Set background color to black
                overflow: "hidden", // Prevent overflow
                borderRadius: "3%",
              }}
            >
              {/* Background Image */}
              <img
                src={images.maskGroup} // Set your background image here
                alt="Background"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute", // Make the image stay in the background
                  top: 0,
                  left: 0,
                  zIndex: 0, // Send image to the back
                }}
              />

              {/* Foreground Image (increased size) */}
              <img
                src={images.adPerson}
                alt="Foreground"
                style={{
                  width: "60%", // Increased width to make the image larger
                  height: "auto",
                  position: "absolute", // Positioning
                  bottom: 0,
                  right: 0,
                  objectFit: "cover",
                }}
              />

              {/* Overlapping text and elements */}
              <Box
                sx={{
                  position: "relative", // Keeps the text above the images
                  textAlign: "left", // Align text to the left
                  color: "white", // Set text color to white for visibility
                  padding: "20px", // Add padding for spacing
                  marginLeft: "30px", // Add space from the left
                  zIndex: 1, // Ensure content appears above background
                }}
              >
                {/* "MAN HAIR CUT" Title */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "39px",
                    fontWeight: 500,
                    lineHeight: 1.2, // Adjust line height if necessary
                    color: "white",
                  }}
                >
                  MAN <br /> HAIR CUT
                </Typography>

                {/* Pricing and "Only" Text */}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "66px",
                      fontWeight: 400,
                      color: "white",
                    }}
                  >
                    ₹99/-
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "28px",
                      fontWeight: 400,
                      color: "white",
                      float: "right",
                      marginTop: "-50px",
                      marginRight: "-20px",
                    }}
                  >
                    Only
                  </Typography>
                </Box>

                {/* "Book Now" Button */}
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "60px",
                    padding: "15px 30px", // Padding for the button look
                    marginTop: "30px", // Space between elements
                    display: "inline-block",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#231f20",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Book Now
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                height: "500px",
                width: "100%",
                position: "relative", // Relative positioning to allow absolute children
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "flex-start", // Align contents to the left
                backgroundColor: "black", // Set background color to black
                overflow: "hidden", // Prevent overflow
                borderRadius: "3%",
                left:"25px",
              }}
            >
              {/* Background Image */}
              <img
                src={images.maskGroup} // Set your background image here
                alt="Background"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute", // Make the image stay in the background
                  top: 0,
                  left: 0,
                  zIndex: 0, // Send image to the back
                }}
              />

              {/* Foreground Image (increased size) */}
              <img
                src={images.adPerson}
                alt="Foreground"
                style={{
                  width: "60%", // Increased width to make the image larger
                  height: "auto",
                  position: "absolute", // Positioning
                  bottom: 0,
                  right: 0,
                  objectFit: "cover",
                }}
              />

              {/* Overlapping text and elements */}
              <Box
                sx={{
                  position: "relative", // Keeps the text above the images
                  textAlign: "left", // Align text to the left
                  color: "white", // Set text color to white for visibility
                  padding: "20px", // Add padding for spacing
                  marginLeft: "30px", // Add space from the left
                  zIndex: 1, // Ensure content appears above background
                }}
              >
                {/* "MAN HAIR CUT" Title */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "39px",
                    fontWeight: 500,
                    lineHeight: 1.2, // Adjust line height if necessary
                    color: "white",
                  }}
                >
                  MAN <br /> HAIR CUT
                </Typography>

                {/* Pricing and "Only" Text */}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "66px",
                      fontWeight: 400,
                      color: "white",
                    }}
                  >
                    ₹99/-
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "28px",
                      fontWeight: 400,
                      color: "white",
                      float: "right",
                      marginTop: "-50px",
                      marginRight: "-20px",
                    }}
                  >
                    Only
                  </Typography>
                </Box>

                {/* "Book Now" Button */}
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "60px",
                    padding: "15px 30px", // Padding for the button look
                    marginTop: "30px", // Space between elements
                    display: "inline-block",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#231f20",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Book Now
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {dashboardData?.vendor_list?.map((data: any, ind: any) => (
          <Grid
            item
            xs={12}
            key={ind}
            sx={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}
          >
            <center>
              <Typography variant="h3" className="salonBoxTitle">
                {data.title}
              </Typography>
            </center>
            <Grid container spacing={2} className="recommendedSection">
              {data?.data?.map((item: any, index: any) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  onClick={() => gotoShop(item?.vendor_detail_id)}
                >
                  <ImageListItem>
                    <img
                      src={item?.vendor_media?.media_name || images.candvBeauty}
                      alt={item.business_name}
                    />
                    <ImageListItemBar
                      title={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {/* Business Name on the left */}
                          <Typography variant="body1" className="boxTitle">
                            {item.salon_type === "5" && (
                              <img
                                className="businessListImage"
                                src={images.crown}
                                alt="Crown"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 5,
                                  marginBottom: "-8px",
                                  marginLeft: "-3px",
                                }} // Set size and margin
                              />
                            )}
                            {item.business_name}
                          </Typography>

                          {/* Hair Salon and Vector Icon on the right */}
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <Typography
                              variant="body2"
                              className="categoryBoxTitle"
                              sx={{
                                width: "90px",
                                height: "30px",
                                flexShrink: 0,
                                borderRadius: "60px",
                                backgroundColor: "#f1f1f1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center", // Center the text vertically and horizontally
                                color: "#231f20",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "120%", // 16.8px
                              }}
                            >
                              {item?.categories && item?.categories?.length > 0
                                ? item?.categories[0]?.category_name
                                : "N/A"}
                            </Typography>
                            {/* Vector Icon positioned absolutely */}
                            <CardMedia
                              component="img"
                              alt="Vector"
                              src={icons.recommendedSearch}
                              sx={{
                                width: 40,
                                height: 40,
                                position: "absolute",
                                bottom: 30,
                                right: 40,
                                transform: "translate(50%, 50%)", // Adjust to fit within the box
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                gotoShop(item?.vendor_detail_id);
                              }}
                            />
                          </Box>
                        </Box>
                      }
                      subtitle={
                        <div>
                          <Typography variant="body2">
                            {item.vendor_address?.city_name},{" "}
                            {item.vendor_address?.state_name}
                          </Typography>
                          <Typography variant="body2">
                            {item.rating}{" "}
                            <img
                              className="rating"
                              src={icons.rating}
                              alt="Rating"
                            />
                          </Typography>
                        </div>
                      }
                    />
                  </ImageListItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}

        <Grid container marginTop={10}>
          <Grid item xs={12}>
            <Box
              className="box"
              sx={{
                height: "752px",
                width: "100%", // Set width to 100% to make it responsive
                backgroundColor: "#f6f6f6",
                padding: "90px 240px", // Padding for top and left
                boxSizing: "border-box", // Ensure padding is included in total height/width
              }}
            >
              <Grid container spacing={4} sx={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontFamily: "Poppins, Helvetica",
                      lineHeight: "43.2px",
                    }}
                  >
                    Glam9 Subscription
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: 2,
                      textAlign: "center",
                      fontFamily: "Poppins, Helvetica",
                      lineHeight: "26px",
                      fontWeight: "400",
                    }}
                  >
                    You can use Glam9 free for up to 14 Days,
                    <br />
                    with full access to everything offered on the plan you sign
                    up for.
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ marginTop: 3 }}
                >
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          opacity: 0.4,
                          fontFamily: "Poppins, Helvetica",
                          marginLeft: 1,
                        }}
                      >
                        Monthly
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ToggleButton value="check" selected>
                        <img
                          alt="Group"
                          src={images.group36}
                          style={{ height: "30px", width: "51px" }}
                        />
                      </ToggleButton>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        fontFamily: "Poppins, Helvetica",
                      }}
                    >
                      Yearly
                    </Typography>
                  </Grid>
                </Grid>

                {/* Pricing Plans */}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #231f2033",
                        borderRadius: "15px",
                        padding: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontFamily: "Poppins, Helvetica",
                          textAlign: "center",
                        }}
                      >
                        ₹999/mo.
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Poppins, Helvetica",
                          lineHeight: "22.4px",
                          opacity: 0.7,
                          textAlign: "center",
                        }}
                      >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 1,
                        }}
                      >
                        <div className="ellipse" />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      sx={{
                        backgroundColor: "#ffffff",
                        border: "1px solid #231f2033",
                        borderRadius: "15px",
                        padding: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontFamily: "Poppins, Helvetica",
                          textAlign: "center",
                        }}
                      >
                        ₹1999/mo.
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Poppins, Helvetica",
                          lineHeight: "22.4px",
                          opacity: 0.7,
                          textAlign: "center",
                        }}
                      >
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 1,
                        }}
                      >
                        <div className="group-4" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="center"
                  sx={{ marginTop: 3 }}
                >
                  <Button className="subscribeButton">Subscribe</Button>
                  {/* <ButtonWrapper
                    buttonDivClassName="button-instance"
                    buttonText="Subscribe"
                  /> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container marginTop={10}>
          <Grid item xs={12}>
            <Box
              className="box"
              sx={{
                height: "814px",
                width: "100%",
                borderRadius: "30px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                className="group"
                sx={{
                  height: "814px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Paper
                  sx={{
                    background:
                      "linear-gradient(180deg, rgb(46, 45, 58) 0%, rgb(31, 31, 34) 100%)",
                    borderRadius: "30px",
                    height: "600px",
                    position: "absolute",
                    top: "214px",
                    left: 0,
                    right: 0,
                  }}
                />
                <Typography
                  className="download-the"
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "70px",
                    fontWeight: 600,
                    position: "absolute",
                    top: "274px",
                    left: "80px",
                  }}
                >
                  Download the
                  <br />
                  Galm9 app
                </Typography>
                <Typography
                  className="manage-your-schedule"
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "30px",
                    fontWeight: 400,
                    position: "absolute",
                    top: "472px",
                    left: "80px",
                  }}
                >
                  Manage your schedule effortlessly, and indulge
                  <br />
                  in self-care that fits your lifestyle
                </Typography>

                {/* Group Wrapper for the first image */}
                <Box
                  className="group-wrapper"
                  sx={{
                    height: "100px",
                    position: "absolute",
                    top: "634px",
                    left: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="img"
                    alt="Group"
                    src={images.group142842}
                    style={{ height: "58px", width: "58px" }}
                  />
                </Box>

                {/* Group Wrapper for the second image */}
                <Box
                  className="img-wrapper"
                  sx={{
                    height: "100px",
                    position: "absolute",
                    top: "634px",
                    left: "160px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="group-2"
                    alt="Group"
                    src={images.group142843}
                    style={{ height: "58px", width: "58px" }}
                  />
                </Box>

                {/* QR Code Wrapper */}
                <Box
                  className="QR-code-for-mobile-wrapper"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    height: "142px",
                    position: "absolute",
                    top: "613px",
                    left: "250px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="QR-code-for-mobile"
                    alt="Qr code for mobile"
                    src={images.qrcode}
                    style={{
                      height: "132px",
                      width: "132px",
                      objectFit: "cover",
                    }}
                  />
                </Box>

                {/* Overlap Group */}
                <Box
                  className="overlap-group-wrapper"
                  sx={{
                    height: "684px",
                    position: "absolute",
                    top: "0",
                    left: "75%",
                    width: "335px",
                  }}
                >
                  <Paper
                    className="overlap-group"
                    sx={{
                      backgroundImage: `url(${images.group142845})`,
                      backgroundPosition: "50% 50%",
                      backgroundSize: "cover",
                      height: "684px",
                      position: "relative",
                      width: "333px",
                      backgroundColor: "transparent",
                    }}
                  ></Paper>
                </Box>

                {/* Overlap Wrapper */}
                <Box
                  className="overlap-wrapper"
                  sx={{
                    height: "616px",
                    position: "absolute",
                    top: "75px",
                    left: "60%",
                    width: "302px",
                  }}
                >
                  <Paper
                    className="glam-wrapper"
                    sx={{
                      backgroundImage: `url(${images.group142846})`,
                      backgroundPosition: "50% 50%",
                      backgroundSize: "cover",
                      height: "616px",
                      position: "relative",
                      width: "300px",
                      backgroundColor: "transparent",
                    }}
                  ></Paper>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container marginTop={10} marginBottom={10}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "766px",
                position: "relative",
                fontSize: "16px",
                color: "#231f20",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  borderRadius: "20px",
                  backgroundColor: "#f2f2f2",
                  width: "100%",
                  height: "766px",
                }}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: "80px",
                  left: "0px",
                  width: "100%",
                  height: "530px",
                }}
              >
                <Box
                  sx={{
                    width: "355px",
                    height: "60px",
                    margin: "0 auto",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "500px",
                      border: "1px solid #231f20",
                      width: "355px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: "120%",
                        textTransform: "uppercase",
                        fontSize: "32px",
                      }}
                    >
                      Customer Words
                    </Typography>
                  </Paper>
                </Box>

                {/* Repeatable Review Boxes */}
                      
                <Grid className="testimonial" container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                  {[...Array(4)].map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Box sx={{ position: 'relative', height: '100%' }}>
                        <Paper 
                          sx={{
                            boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.15)',
                            borderRadius: '20px',
                            backgroundColor: '#fff',
                            height: '100%',
                            position: 'relative',
                            padding: '16px',
                          }}
                        >
                        <Typography
                          sx={{
                            lineHeight: '1.8',
                            display: 'inline-block',
                            width: '100%',
                            height: 'auto',
                            mb: 2,
                            fontSize: {
                              xs: '12px',  // Small screen (mobile)
                              sm: '12px',  // Small devices (600px and up)
                              md: '14px',  // Medium devices (900px and up)
                              lg: '14px',  // Large devices (1200px and up)
                              xl: '18px',  // Extra large devices (1536px and up)
                            },
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer took a galley
                          of type and scrambled it to make a type specimen book.
                        </Typography>

                        <img
                          alt=""
                          src={images.rating}
                          style={{
                            position: 'relative',
                            width: 'auto',
                            height: '20px',
                            marginBottom: '16px',
                          }}
                        />

                        <Box
                          sx={{
                            alignItems: 'center',
                            mt: 2,
                            position: 'relative',
                          }}
                          className="reviewSection"
                        >
                          <img
                            alt=""
                            src={images.ellipse12}
                            className="groupInner"
                            style={{
                              borderRadius: '50%',
                              width: '50px',
                              height: '50px',
                              objectFit: 'cover',
                              marginRight: '16px',
                            }}
                          />
                          <Box>
                            <Typography className="jack" sx={{ fontWeight: 'bold',left: {
                                  xs: '31%',  // Small screen (mobile)
                                  sm: '25%',  // Small devices (600px and up)
                                  md: '27%',  // Medium devices (900px and up)
                                  lg: '25%',  // Large devices (1200px and up)
                                  xl: '17%',  // Extra large devices (1536px and up)
                                },   }}>
                              Jack
                            </Typography>
                            <Typography
                              className="newYorkUsa"
                              sx={{ opacity: 0.6, fontSize: '16px',
                                left: {
                                  xs: '31%',  // Small screen (mobile)
                                  sm: '25%',  // Small devices (600px and up)
                                  md: '27%',  // Medium devices (900px and up)
                                  lg: '25%',  // Large devices (1200px and up)
                                  xl: '17%',  // Extra large devices (1536px and up)
                                },  

                               }}
                            >
                              New York, USA
                            </Typography>
                          </Box>
                          
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                ))}
              </Grid>       


                <Box
                  sx={{
                    position: "relative",
                    top: "30.00px",
                    display: "flex",
                    width: "10%",
                    height: "54.6px",
                    margin: "0 auto",
                  }} 
                >
                  <img
                    alt=""
                    src={images.group_arrows}
                    style={{
                      width: "200.6px",
                      height: "54.6px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
