import { getDashboardData } from "../../src/api/dashboardAPI";
import { ApiResponseFromAPI } from "../../src/api/types/common";
import { DashboardResponse } from "../../src/api/types/dashboard";
export const DashboardAPI = () => {
  const getDashboardDataAPICall = async (
    search_keyword: string,
    location: string,
    sort_by: string,
    salon_type: string,
  ): Promise<DashboardResponse | null> => {
    try {
      const response: ApiResponseFromAPI = await getDashboardData(
        search_keyword,
        location,
        sort_by,
        salon_type,
      );

      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse; // Cast to CityListResponses
    }
  };

  return { getDashboardDataAPICall };
};
