import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";

const Header = (): JSX.Element => {
  return (
    <>
      {/* Main Container */}
      <Box
        sx={{
          width: "100%",
          height: "auto", // Make height responsive
          top: 0,
          left: 0,
          backgroundColor: "#f6f6f6",
          padding: { xs: "20px", md: "40px" }, // Responsive padding for mobile and desktop
        }}
      >
        {/* AppBar */}
        <AppBar position="relative" color="transparent" elevation={0}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* Logo */}
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Poppins-Thin,Helvetica",
                fontWeight: "normal",
                fontSize: { xs: "2rem", md: "3rem" }, // Responsive font size
              }}
            >
              <span style={{ fontWeight: "250" }}>Glam</span>
              <span
                style={{
                  fontFamily: "Poppins-ExtraBold,Helvetica",
                  fontWeight: "bolder",
                }}
              >
                9
              </span>
            </Typography>

            {/* Menu Box */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 2, md: 4 }, // Responsive gap between items
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "1rem", md: "20px" }, // Responsive font size
                }}
              >
                Download App
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "1rem", md: "20px" }, // Responsive font size
                }}
              >
                Customer Support
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "1rem", md: "20px" }, // Responsive font size
                }}
              >
                Login | SignUP
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  borderColor: "black",
                  color: "black",
                  textTransform: "capitalize",
                  fontSize: "20px",
                  fontWeight: "400",
                  padding: { xs: "5px 10px", md: "10px 20px" }, // Responsive button padding
                }}
              >
                For Business
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      {/* Divider */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          top: 0,
          left: 0,
          backgroundColor: "black",
        }}
      />
    </>
  );
};

export default Header;
