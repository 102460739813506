// Importing necessary modules and custom hooks
import { useEffect } from 'react';
import axios from '../apis/axios';

// Custom hook for intercepting Axios requests and responses
const useAxios = () => {
  // Retrieving device details using the useDeviceDetails hook

  useEffect(() => {
    // Request interceptor: Adding device details to the request headers
    const requestIntercept = axios.interceptors.request.use(
      (config: any) => {
        return config;
      },
      async (error) => Promise.reject(error),
    );

    // Response interceptor: Merging response data with nested 'data' property
    const responseIntercept = axios.interceptors.response.use(
      (response) => {
        response.data = { ...response.data, ...response.data?.data };
        return response;
      },
      async (error) => {
        return Promise.reject(error);
      },
    );

    // Cleanup: Ejecting the interceptors when the component unmounts
    return () => {
      axios.interceptors.response.eject(responseIntercept);
      axios.interceptors.request.eject(requestIntercept);
    };
  }, []); // Empty dependency array to run the effect only once during component mount

  // Returning the modified axios instance
  return axios;
};

export default useAxios;
