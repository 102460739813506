// Importing necessary modules and custom hooks
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { axiosPrivate } from '../apis/axios';
import useAuth from './useAuth';
import CommonCookie from '../../components/CommonCookie';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  useEffect(() => {
    // Request interceptor: Adding Authorization header and device details to the request
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${cookies.token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Response interceptor: Merging response data with the top-level properties
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => {
        if (response?.config?.method === 'get') {
          return { ...response, ...response.data };
        }
        if (response?.data?.status_code === 400) {
          toast.error(response?.data?.message);
          removeCookie(CommonCookie.user, { path: '/' });
          removeCookie(CommonCookie.token, { path: '/' });
          removeCookie(CommonCookie.userType, { path: '/' });
          removeCookie(CommonCookie.profile, { path: '/' });
          removeCookie(CommonCookie.vendor_id, { path: '/' });
          navigate("/about");
        }
        return response;
      },
      async (error) => {
        // Handling 401 Unauthorized errors and refreshing the access token
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    // Cleanup: Ejecting the interceptors when the component unmounts or when dependencies change
    return () => {
      axiosPrivate.interceptors.response.eject(responseIntercept);
      axiosPrivate.interceptors.request.eject(requestIntercept);
    };
  }, [auth]);

  // Returning the modified axios instance for private requests
  return axiosPrivate;
};

export default useAxiosPrivate;
