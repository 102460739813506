import React, { useState, useEffect } from "react";
import { Box, Grid, Paper } from '@mui/material';
//import LoginForm from './loginForm/loginForm';
import LoginForm from "./loginForm/loginForm";
import BrandLogo from './brandLogo/brandLogo';
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../../Services/auth.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../components/CommonCookie";
import './Login.css';

const LoginPage: React.FC = () => {
  

  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState("");
  const navigate = useNavigate();
  const authService = AuthService();
  
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  // function for email validation and redirect to next screen after successfull validation

  useEffect(() => {
    if (cookies.token) {
      navigate("/");
    }
  }, [])

  function addEmail() {
    setEmailerror("");
    let isValid = true;
    if (email === "") {
      setEmailerror("Phone Number should not be blank");
      isValid = false;
    }

    if (isValid) {
      sendOtp();
    }
  }

  const sendOtp = async () => {
    const fd = new FormData();
    fd.append("country_code", "91" || "");
    fd.append("country_iso", "in" || "");
    fd.append("mobile", email || "");
    try {
      const response = await authService().sendOtp(fd);
      if (response.data.success) {
        navigate("/otp");
        navigate("/otp", { state: { number: email } });
      } else {
        navigate("/account");
      }
    } catch (error: any) {
    }
  };

  function backToLanding() {
    navigate("/");
  }


  return (
    <Box className="main-container login">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} className="login-section">
          <Paper elevation={3} className="login-form-container">
          <LoginForm
              email={email}
              setEmail={setEmail}
              emailerror={emailerror}
              addEmail={addEmail}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b9054a0b4127e86b78a9f4f973a0a9f9059d7880a7018e3a8b6fd618dd83816e?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
              alt=""
              className="brand-image"
            />
            <BrandLogo />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
