// src/api/userApi.ts

import { apiClient } from "./apiClient";
import { ApiResponseFromAPI } from "./types/common";

// Get City List
export const getCityList = (stateId: number, countryId: number) => {
  return apiClient<ApiResponseFromAPI>(
    `/get-location?stateId=${stateId}&countryId=${countryId}`,
    { method: "GET" },
  );
};

// Get Dropdown List Options
export const getDropdownListOptions = () => {
  return apiClient<ApiResponseFromAPI>(`/get-dropdown-list`, { method: "GET" });
};
