// src/api/apiClient.ts

// Common interface for all API requests
export interface ApiRequestOptions {
  method: "GET" | "POST" | "PUT" | "DELETE";
  body?: any; // For POST/PUT requests
  headers?: Record<string, string>;
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your API base URL

// Common function to call any API
export const apiClient = async <T>(
  endpoint: string,
  options: ApiRequestOptions,
): Promise<T> => {
  const { method, body, headers = {} } = options;
  const config: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, config);

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data as T;
};
