// src/hooks/useApi.ts
import { getCityList, getDropdownListOptions } from "../../src/api/commonAPI";
import {
  CityListResponse,
  ApiResponseFromAPI,
} from "../../src/api/types/common";
export const CommonAPI = () => {
  const getCityListAPICall = async (
    stateId: number,
    countryId: number,
  ): Promise<CityListResponse | null> => {
    try {
      const response: ApiResponseFromAPI = await getCityList(
        stateId,
        countryId,
      );
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as CityListResponse; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as CityListResponse; // Cast to CityListResponses
    }
  };

  const getDropdownListOptionsAPICall = async (): Promise<any | null> => {
    try {
      const response: any = await getDropdownListOptions();
      return {
        data: response.data, // Ensure this matches City[]
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as any; // Cast to CityListResponses
    } catch (err: any) {
      return {
        data: [], // Ensure this matches City[]
        message: err.toString(),
        status_code: 500,
        success: false,
      } as any; // Cast to CityListResponses
    }
  };
  return { getCityListAPICall, getDropdownListOptionsAPICall };
};
