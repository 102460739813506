// Importing necessary modules and the AuthContext
import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';

// Custom hook for accessing the authentication context
const useAuth: any = () => {
  // Using the useContext hook to retrieve the AuthContext
  return useContext(AuthContext);
};

export default useAuth;
