// src/api/userApi.ts

import { apiClient } from "./apiClient";
import { ApiResponseFromAPI } from "./types/common";

// Get Dashboard Data
export const getDashboardData = (
  search_keyword: string,
  location: string,
  sort_by: string,
  salon_type: string,
) => {
  return apiClient<ApiResponseFromAPI>(
    `/customer-dashboard?search_keyword=${search_keyword}&location=${location}&sort_by=${sort_by}&salon_type=${salon_type}`,
    { method: "GET" },
  );
};
