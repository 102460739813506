import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/common/Header/Header";
import Footer from "./components/common/Footer/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
// import About from "./pages/About";

const App: React.FC = () => (
  <Router>
    <Header />
    <div style={{ minHeight: "80vh" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        {/* <Route path="/about" element={<About />} /> */}
      </Routes>
    </div>
    <Footer />
  </Router>
);

export default App;
