import React, { useState, useEffect } from "react";
import VerificationInput from "./VerificationInput/verificationInput";
import { Box,Paper,  Typography,IconButton, Button as MuiButton, Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import BrandLogo from "../login/brandLogo/brandLogo";
import { Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { createTheme, Theme } from '@mui/material/styles';
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import "./Otp.css";

interface VerifyPhoneProps {
  phoneNumber: string;
}



const Otp: React.FC<VerifyPhoneProps> = ({ phoneNumber }) => {
  function back(){
  
  }
  const [otp, setOtp] = useState<string>("");
  const handleChange = (otp: string) => {
    setOtp(otp);
  };  

  const t = useAppTranslation();
  return (

    <Box className="main-container login">
    <Grid container spacing={2} className="grid-container">
      <Grid item xs={12} md={6} className="login-section">
        <Paper elevation={3} className="login-form-container">
        <Container className="login-form" maxWidth="sm">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebbcbde83d2d7b86ba57a33b485526d6c79851fd3657a86b1668e0a206419350?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
        alt=""
        className="login-form-logo"
      />
      <Typography variant="h4" component="h1" className="login-form-title">
      {t("verifyPhone")}
      </Typography>
      <Typography variant="body1" className="login-form-subtitle">
      {t("otpSent")}
      </Typography>
      <center>
      <Grid container justifyContent="center" className="pwdSection otp">
        <Box className="otp-input">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
            inputStyle={{ width: "2rem", height: "2rem", margin: "0 0.5rem" }}
          />
        </Box>
      </Grid>

      <Grid container direction="column" alignItems="center" className="otp">
        <Typography variant="body2" color="error">
        </Typography>
        <Typography variant="h4">
          {t("didntReceiveCode")} <Link to="#">{t("resend")}</Link>
        </Typography>
        <Button variant="contained" color="primary" fullWidth className="login-form-button">
        Continue
      </Button>
      </Grid>

      </center>
    </Container>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} className="logo-section">
        <Box className="brand-container">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b9054a0b4127e86b78a9f4f973a0a9f9059d7880a7018e3a8b6fd618dd83816e?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt=""
            className="brand-image"
          />
          <BrandLogo />
        </Box>
      </Grid>
    </Grid>
  </Box> 


    
  );
};

export default Otp;
