import React from 'react';
import { Button, Typography, Box, TextField, Container } from '@mui/material';
import PhoneInput from '../phoneInput/phoneInput';
import './loginForm.css';

interface LoginFormProps {
  addEmail: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailerror: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ addEmail, email, setEmail, emailerror }) => {
  return (
    <Container className="login-form" maxWidth="sm">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebbcbde83d2d7b86ba57a33b485526d6c79851fd3657a86b1668e0a206419350?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
        alt=""
        className="login-form-logo"
      />
      <Typography variant="h4" component="h1" className="login-form-title">
        Login
      </Typography>
      <Typography variant="body1" className="login-form-subtitle">
        Enter your phone number to login
      </Typography>
      <center><PhoneInput email={email} setEmail={setEmail} emailerror={emailerror} />
      <Button variant="contained" color="primary" onClick={addEmail} fullWidth className="login-form-button">
        Continue
      </Button></center>
      <Typography variant="body2" className="login-form-footer">
        Have a business account? <br />
        Sign in as a professional
      </Typography>
    </Container>
  );
};

export default LoginForm;