// Define an object to hold common cookie names
const CommonCookie: any = {
  token: 'token',
  user: 'user',
  userType: 'user_type',
  profile: 'profile',
  vendor_id: 'vendor_id',
};

export default CommonCookie;
